<template>
  <div>
    <div class="nav">
      <div class="layout-nav">
        <div class="nav-list dis-flex space-between margin-auto-0">
          <div class="nav-list-l dis-flex">
            <div class="nav-list-ls">
            </div>
            <ul class="head-lists dis-flex">
              <li
                  class="head-navba"
              >
              </li>
              <li
                  class="head-navbaac"
              >
                班级管理
              </li>
              <li
                  class="head-navba"
              >

              </li>
              <li
                  class="head-navba"
              >
              </li>
            </ul>
          </div>

        </div>
      </div>
    </div>
    <div class="class_nav">
      <span>当前位置：</span> <span>班级管理</span>
    </div>
    <ul v-if="classList.length>0" class="class_list dis-flex ">
      <li class="class_item dis-flex"
          v-for="(item,index) in classList" :key="index">
        <div class="class_item-l dis-flex">
          <div class="class_item-l-img">
            <img :src="item.pic" alt="">
          </div>
          <div class="class_item-text">
            <h3>{{ item.name }}</h3>
            <p>学校:{{ item.schoolname }}</p>
          </div>
        </div>
        <div class="class_item-r ">
          <div class="class-detail cursor-pointer" @click="classdetail(item)">查看详情&nbsp<span>></span></div>
          <div class="class-audit cursor-pointer" @click="toaudit(item)">成员审核&nbsp<span>></span></div>
        </div>
      </li>

    </ul>
    <div v-else class="wushuju">
      <img src="../../assets/images/wushuju.png" alt="">
<!--      <p class="wushuju-text">暂无数据</p>-->
    </div>
  </div>
</template>

<script>
import api from '@/service/api/api'

export default {
  name: "classBa",
  data() {
    return {
      title: '',
      classList: []
    }
  },
  methods: {
    // 初始化接口
    init() {
      api.classList().then(res => {
        console.log(res)
        if (res.errcode === 0) {
          this.classList = res.data
        } else {
          this.$message({
            message: res.errmsg,
            duration: 2000,
            type: "warning"
          });
        }

      }).catch(err => {
        console.log(err)
      })
    },
    // 查看详情
    classdetail(item) {
   this.$router.push({
     path:'/classDetail',
     query:{
       id:item.id
     }
   })
    },
   // 班级审核
    toaudit(item) {
      this.$router.push({
        path:'/classcheck',
        query:{
          id:item.id
        }
      })
    }
  },
  created() {

    this.init()

  }
}
</script>

<style scoped>
.nav {
  width: 100%;
  height: 60px;
  background: linear-gradient(90deg, #FFF1F1 0%, #FDF9E9 100%);
  opacity: 0.5;
  /*margin-top: -20px;*/
}
.class_nav {
  width: 1200px;
  margin: 0 auto;
  height: 40px;
  line-height: 40px;
  color: #FF8E42;
}

.class_nav span:first-child {
  color: #999999;
}

.class_list {
  width: 1200px;
  margin: 0 auto;
  min-height: 100px;
  flex-wrap: wrap;
}

.class_item {
  width: 330px;
  height: 90px;
  background: #FFFFFF;
  box-shadow: 0 0 21px 3px rgba(4, 0, 0, 0.05);
  border-radius: 8px;
  margin-right: 20px;
  margin-top: 20px;
}

.class_item-l {
  width: 69%;
  height: 70px;
  margin: 10px;
}

.class_item-l-img {
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  border-radius: 22px 0 22px 0;
}

.class_item-l-img > img {
  width: 100%;
  height: 100%;
  border-radius: 22px 0 22px 0;
}

.class_item-text {
  width: 100px;
  height: 70px;
  margin-left: 10px;

}

.class_item-text > h3 {
  width: 133px;
  font-size: 18px;
  font-weight: 400;
  color: #000000;
  margin-top: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.class_item-text p {
  width: 133px;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  margin-top: 22px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.class_item-r {
  width: 31%;
  height: 70px;
  margin: 10px 0 0 0;
  font-size: 20px;
  color: #FFFFFF;
  text-align: center;
  margin-right: 10px;
  /*background-color: pink;*/
}

.class-detail {
  color: #FF8E42;
  font-weight: 400;
  margin-top: 3px;
  padding-left: 20px;
  /*background-color: black;*/
}

.class-audit {
  width: 91px;
  height: 30px;
  background: #FF8E42;
  border-radius: 8px;
  text-align: center;
  line-height: 30px;
  margin: 17px 0 0 1px;
}
.wushuju {
  width: 235px;
  height: 600px;
  margin: 100px auto  ;
}

.wushuju > img {
  width: 235px;
  height: 196px;
}

.wushuju-text {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #999999;
  font-size: 22px;
  margin-top: 20px;
}
.layout-nav {
  width: 100%;
  height: 60px;
  line-height: 60px;
  margin-top: -4px;
}

.nav-list {
  width: 80%;
  height: 60px;
}
.nav-list-l {
  width: 586px;
  /*height: 24px;*/
  font-size: 20px;
  font-weight: bold;
  margin-top: 5px;
  color: #6094FF;
}

.nav-list-ls {
  width: 86px;
  height: 24px;
  font-size: 20px;
  font-weight: bold;
  /*margin-top: 5px;*/
  color: #6094FF;
}

.nav-list-ls > img {
  width: 86px;
  height: 24px;
  margin-top: 16px;
}

.head-navbaac {
  width: 84px;
  height: 60px;
  background: #FFFFFF;
  font-size: 16px;
  font-weight: 400;
  color: #111111;
  text-align: center;
  cursor: pointer;
  margin: 0 10px;
  border-bottom: 2px solid #FF7800;
}
.head-navba {
  width: 84px;
  height: 60px;
  margin: 0 10px;
}

.head {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 10px 10px 0 0;
  position: relative;
  cursor: pointer;
}

.head > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.head-name {
  min-width: 42px;
  height: 12px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}

</style>